import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'

export const AboutPageTemplate = ({
  title,
  about,
  background,
  clientList
}) => {
  return (
    <div className="container content">
      <div id="about_wrapper">
        <div className="context-text">
          <span className="about-title">About</span>
          <p className="context-text-body">{about}</p>
          
          <span className="about-title">Background</span>
          <p className="context-text-body">{background}</p>
          
          <span className="about-title">Contact</span>
          <p className="context-text-body"><a className="animate-hover" href="mailto:louise@studiolouise.co">louise@studiolouise.co</a></p>
        </div>
        
        <div className="client-list">
          <span className="about-title">Partners</span>
          <p className="client-list-body">
            {clientList.sort((a, b) => {
              const textA = a.toUpperCase()
              const textB = b.toUpperCase()

              return textA.localeCompare(textB)
            }).map((client, i) => <span className="client-item" key={client}>{client}</span>)}
          </p>
        </div>
      </div>
    </div>
  )
}

AboutPageTemplate.propTypes = {
  about: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  clientList: PropTypes.array.isRequired,
}

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <AboutPageTemplate
        about={post.frontmatter.about}
        background={post.frontmatter.background}
        clientList={post.frontmatter.clientList}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage {
    markdownRemark(frontmatter: {templateKey: {eq: "about-page"}}) {
      html
      frontmatter {
        about
        background
        clientList
      }
    }
  }
`
